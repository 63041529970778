<template>
  <div class="vx-row">
    <div class="vx-col w-full">

      <vs-popup title="Règlement infos" :active.sync="popupReglementInfoAll">
        <div class="vx-row">
          <div class="vx-col w-full mt-5">
            <label for="dateRegl">Date</label>
            <datepicker class="w-full"  format="d MMMM yyyy" name="dateRegl" v-model="dateRegl"></datepicker>
          </div>
          <div class="vx-col w-full mt-5">
            <label for="modePayement">Mode de payement</label>
            <vs-select autocomplete class="selectExample w-full select-large" v-model="modePayement">
              <vs-select-item :key="index" :value="item.libelle" :text="item.value" v-for="(item,index) in moyen_paiement" />
            </vs-select>
          </div>
          <div class="vx-col w-full mt-5">
            <label for="banque">Banque</label>
            <vs-select autocomplete class="selectExample w-full select-large" v-model="banque">
              <vs-select-item :key="index" :value="item.NomBanque" :text="item.NomBanque" v-for="(item,index) in banques" />
            </vs-select>
          </div>
          <div class="vx-col w-full mt-5">
            <label for="numeroCheq">N°chèque</label>
            <vs-input class="w-full" name="numeroCheq" :value="numeroCheq" @change="numeroCheq=$event.target.value" />
          </div>
          <div class="vx-col w-full mt-5">
            <label for="numeroCompteBq">N°compte bancaire (RIB)</label>
            <vs-input class="w-full" name="numeroCompteBq" :value="numeroCompteBq" @change="numeroCompteBq=$event.target.value" />
          </div>
          <div class="vx-col w-full mt-5">
            <label for="remarqueReglement">Observation</label>
            <vs-textarea class="w-full" name="remarqueReglement" :value="remarqueReglement" @change="remarqueReglement=$event.target.value" />
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="soldeAllFactureAssurance">VALIDER</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="popupReglementInfoAll = false;popupReglementFactureAssurance = true;">ANNULER</vs-button>
          </div>
        </div>
      </vs-popup>

      <vs-popup title="Règlement infos" :active.sync="popupReglementInfo">
        <div class="vx-row">
          <div class="vx-col w-full mt-5">
            <label for="dateRegl">Date</label>
            <datepicker class="w-full"  format="d MMMM yyyy" name="dateRegl" v-model="dateRegl"></datepicker>
          </div>
          <div class="vx-col w-full mt-5">
            <label for="modePayement">Mode de payement</label>
            <vs-select autocomplete class="selectExample w-full select-large" v-model="modePayement">
              <vs-select-item :key="index" :value="item.libelle" :text="item.value" v-for="(item,index) in moyen_paiement" />
            </vs-select>
          </div>
          <div class="vx-col w-full mt-5">
            <label for="banque">Banque</label>
            <vs-select autocomplete class="selectExample w-full select-large" v-model="banque">
              <vs-select-item :key="index" :value="item.NomBanque" :text="item.NomBanque" v-for="(item,index) in banques" />
            </vs-select>
          </div>
          <div class="vx-col w-full mt-5">
            <label for="numeroCheq">N°chèque</label>
            <vs-input class="w-full" name="numeroCheq" :value="numeroCheq" @change="numeroCheq=$event.target.value" />
          </div>
          <div class="vx-col w-full mt-5">
            <label for="numeroCompteBq">N°compte bancaire (RIB)</label>
            <vs-input class="w-full" name="numeroCompteBq" :value="numeroCompteBq" @change="numeroCompteBq=$event.target.value" />
          </div>
          <div class="vx-col w-full mt-5">
            <label for="remarqueReglement">Observation</label>
            <vs-textarea class="w-full" name="remarqueReglement" :value="remarqueReglement" @change="remarqueReglement=$event.target.value" />
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="soldeIndividualFactureAssurance">VALIDER</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="popupReglementInfo = false;popupReglementFactureAssurance = true;">ANNULER</vs-button>
          </div>
        </div>
      </vs-popup>
      <vs-popup fullscreen title="Règlement facture assurance" :active.sync="popupReglementFactureAssurance">

        <div class="vx-row">
          <div v-if="donnees" class="vx-col w-full">
            <vs-table  noDataText="" pagination max-items="50" stripe search :data="donnees.facturesClient">
              <template slot="header">
                <vs-button v-if="!donnees.solde" color="primary" @click="showReglementInfoAll">SOLDER LA FACTURE</vs-button>
              </template>
              <template slot="thead">
                <vs-th v-if="donnees.champDate">
                  DATE
                </vs-th>
                <vs-th v-if="donnees.champMatricule" sort-key="numero">
                  MATRICULE
                </vs-th>
                <vs-th v-if="donnees.champNomPrenom">
                  NOM & PRENOM
                </vs-th>
                <vs-th v-if="donnees.champMonture" sort-key="Nom">
                  MONTURE
                </vs-th>
                <vs-th v-if="donnees.champVerre" sort-key="Nom">
                  VERRES
                </vs-th>
                <vs-th v-if="donnees.champRemise" sort-key="Nom">
                  REMISE
                </vs-th>
                <vs-th v-if="donnees.champTotal">
                  TOTAL
                </vs-th>
                <vs-th v-if="donnees.champClient">
                  CLIENT
                </vs-th>
                <vs-th v-if="donnees.champAssurance">
                  ASSURANCE
                </vs-th>
                <vs-th>
                  Soldé
                </vs-th>
                <vs-th>
                  Facture
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td v-if="donnees.champDate">
                    {{tr.DateVente | moment("calendar", "July 10 2011")}}
                  </vs-td>
                  <vs-td v-if="donnees.champMatricule" :data="data[indextr].matricule">
                    {{tr.matricule}}
                  </vs-td>
                  <vs-td v-if="donnees.champNomPrenom" :data="data[indextr].patient">
                    {{tr.patient}}
                  </vs-td>
                  <vs-td v-if="donnees.champMonture" :data="data[indextr].PrixMont">
                    {{moneyFormatter((tr.PrixMont ? tr.PrixMont : 0))}}
                  </vs-td>
                  <vs-td v-if="donnees.champVerre">
                    {{moneyFormatter(((tr.PrixVerG ? tr.PrixVerG : 0) + (tr.PrixVerD ? tr.PrixVerD : 0) + (tr.prixPhotogray ? tr.prixPhotogray : 0) + (tr.prixAntiReflet ? tr.prixAntiReflet : 0) + (tr.prixAntiRayure ? tr.prixAntiRayure : 0) + (tr.prixAutreTraitement ? tr.prixAutreTraitement : 0)))}}
                  </vs-td>
                  <vs-td v-if="donnees.champRemise" :data="data[indextr].RemiseCom">
                    {{moneyFormatter(tr.RemiseCom ? tr.RemiseCom : 0)}}
                  </vs-td>
                  <vs-td v-if="donnees.champTotal">
                    {{moneyFormatter(totalNet(tr.PrixMont, tr.PrixVerG, tr.PrixVerD, tr.RemiseCom, tr.prixPhotogray, tr.prixAntiReflet, tr.prixAntiRayure, tr.prixAutreTraitement))}}
                  </vs-td>
                  <vs-td v-if="donnees.champClient">
                    {{moneyFormatter(calculParClient((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.TickForf ? tr.TickForf: 0), tr.prixPhotogray, tr.prixAntiReflet, tr.prixAntiRayure, tr.prixAutreTraitement))}}
                  </vs-td>
                  <vs-td v-if="donnees.champAssurance">
                    {{moneyFormatter(calculParAssurance((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0)), calculParClient((tr.PrixMont ? tr.PrixMont : 0), (tr.PrixVerG ? tr.PrixVerG : 0), (tr.PrixVerD ? tr.PrixVerD : 0), (tr.RemiseCom ? tr.RemiseCom : 0), (tr.PlafForf ? tr.PlafForf : 0), (tr.TickForf ? tr.TickForf: 0), tr.prixPhotogray, tr.prixAntiReflet, tr.prixAntiRayure, tr.prixAutreTraitement), tr.prixPhotogray, tr.prixAntiReflet, tr.prixAntiRayure, tr.prixAutreTraitement)}}
                  </vs-td>
                  <vs-td>
                    <span v-if="tr.regle === false">
                      <vs-chip transparent color="danger">
                        Non
                      </vs-chip>
                    </span>
                    <span v-if="tr.regle === true">
                      <vs-chip transparent color="primary">
                        Oui
                      </vs-chip>
                    </span>
                  </vs-td>
                  <vs-td>
                    <a  @click="factureAssuranceClientDetailPdfGenerator(tr._id)">Générer </a>
                    <a  v-if="tr.pdf" :href="tr.pdf" target="_blank">/ Télécharger</a>
                  </vs-td>
                  <vs-td>
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon v-if="tr.regle === false" title="Soldé facture" icon="CheckSquareIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showReglementInfo(tr)" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

          </div>
        </div>
      </vs-popup>
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table  noDataText="" pagination max-items="50" stripe search :data="factures">
              <template slot="header">
              </template>
              <template slot="thead">
                <vs-th>
                  Période
                </vs-th>
                <vs-th sort-key="numero">
                  Assurance
                </vs-th>
                <vs-th>
                  Part assurance
                </vs-th>
                <vs-th sort-key="Nom">
                  Part client
                </vs-th>
                <vs-th sort-key="Nom">
                  Nombre vente
                </vs-th>
                <vs-th sort-key="Nom">
                  Date depôt
                </vs-th>
                <vs-th>
                  Deposer
                </vs-th>
                <vs-th>
                  Facture
                </vs-th>
                <vs-th>
                  Soldé
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td>
                    {{tr.PeriodeDebut | moment("calendar", "July 10 2011")}} - {{tr.PeriodeFin | moment("calendar", "July 10 2011")}}
                  </vs-td>
                  <vs-td :data="data[indextr].NomAssurance">
                    {{tr.NomAssurance}}
                  </vs-td>
                  <vs-td :data="data[indextr].MontantAssurance">
                    {{moneyFormatter(tr.MontantAssurance)}}
                  </vs-td>
                  <vs-td :data="data[indextr].MontantClient">
                    {{moneyFormatter(tr.MontantClient)}}
                  </vs-td>
                  <vs-td  :data="data[indextr].facturesClient.length">
                    {{tr.facturesClient.length}}
                  </vs-td>
                  <vs-td :data="data[indextr].dateDepot">
                    {{tr.dateDepot | moment("calendar", "July 10 2011")}}
                  </vs-td>
                  <vs-td>
                    <span v-if="tr.depot === false">
                      <vs-chip transparent color="danger">
                        Nom
                      </vs-chip>
                    </span>
                    <span v-if="tr.depot === true">
                      <vs-chip transparent color="primary">
                        Oui
                      </vs-chip>

                    </span>
                  </vs-td>
                  <vs-td>
                    <a  @click="factureAssurancePdfGenerator(tr)">Générer </a>
                    <a  v-if="tr.pdf" :href="tr.pdf" target="_blank">/ Télécharger</a>
                  </vs-td>
                  <vs-td>
                    <span v-if="tr.solde === false">
                      <vs-chip transparent color="danger">
                        Non
                      </vs-chip>
                    </span>
                    <span v-if="tr.solde === true">
                      <vs-chip transparent color="primary">
                        Oui
                      </vs-chip>
                    </span>
                  </vs-td>
                  <vs-td>
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon title="Règlement facture" icon="BookmarkIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showReglementFactureAssurance(tr)" />
                      <feather-icon title="Archiver" icon="ArchiveIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="archiveFactureAssurance(tr._id)" />
                    </div>
                  </vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td>

                  </vs-td>
                  <vs-td>

                  </vs-td>
                  <vs-td>
                    = {{moneyFormatter(totalPartAssurance)}} FCFA
                  </vs-td>
                  <vs-td>
                    = {{moneyFormatter(totalPartClient)}} FCFA
                  </vs-td>
                  <vs-td>
                    = {{totalNbreVente}}
                  </vs-td>
                  <vs-td>
                  </vs-td>
                  <vs-td>
                  </vs-td>
                  <vs-td>
                  </vs-td>
                  <vs-td>
                  </vs-td>
                  <vs-td>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import * as lang from 'vuejs-datepicker/src/locale'
export default {
  components: {
    Datepicker
  },
  data () {
    return {
      IdUser: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo'))._id : null,
      popupReglementFactureAssurance: false,
      popupReglementInfo: false,
      popupReglementInfoAll: false,
      donnees: null,
      idFacture: null,
      dateDepot: null,

      idFactureClient: null,
      montanRegleAssu: null,
      dateRegl: null,
      remarqueReglement: null,
      modePayement: null,
      numeroCheq: null,
      banque: null,
      numeroCompteBq: null,
      preuveReglement: null,

      allFactureClient: null,

      moyen_paiement:
      [
        {libelle: 'Espèce', value: 'Espèce'},
        {libelle: 'Chèque', value: 'Chèque'},
        {libelle: 'Virement bancaire', value: 'Virement bancaire'},
        {libelle: 'Transfert', value: 'Transfert'}
      ],


      language: 'fr',
      languages: lang
    }
  },
  computed: {
    factures () {
      return this.$store.state.facturation.factures_assurance_deposer
    },
    totalPartAssurance () {
      const total = this.factures.length > 0 ? this.factures.reduce((acc, facture) => {
        return acc + (facture.MontantAssurance ? facture.MontantAssurance : 0)
      }, 0) : 0
      return total
    },
    totalPartClient () {
      const total = this.factures.length > 0 ? this.factures.reduce((acc, facture) => {
        return acc + (facture.MontantClient ? facture.MontantClient : 0)
      }, 0) : 0
      return total
    },
    totalNbreVente () {
      const total = this.factures.length > 0 ? this.factures.reduce((acc, facture) => {
        return acc + (facture.facturesClient.length ? facture.facturesClient.length : 0)
      }, 0) : 0
      return total
    },
    banques () {
      return this.$store.state.banque.banques
    }
  },

  methods: {
    totalNet (PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) {
      return ((((+PrixMont ? +PrixMont : 0) + (+PrixVerG ? +PrixVerG : 0) + (+PrixVerD ? +PrixVerD : 0) + (prixPhotogray ? +prixPhotogray : 0) + (prixAntiReflet ? +prixAntiReflet : 0) + (prixAntiRayure ? +prixAntiRayure : 0) + (prixAutreTraitement ? +prixAutreTraitement : 0)) - (RemiseCom ? RemiseCom : 0)))
    },
    calculParClient (PrixMont, PrixVerG, PrixVerD, RemiseCom, plafon, ticket, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) {
      let partClient = null

      if (this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) >= plafon) {
        partClient = this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) - +plafon
      } else if (ticket <= 100) {
        partClient = Math.round(((this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) * ticket) / 100))
      } else {

        partClient = ticket
      }

      return partClient
    },
    calculParAssurance (PrixMont, PrixVerG, PrixVerD, RemiseCom, plafon, PartClient, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement) {
      let PartAssu = null
      const factureNet = this.totalNet(PrixMont, PrixVerG, PrixVerD, RemiseCom, prixPhotogray, prixAntiReflet, prixAntiRayure, prixAutreTraitement)
      if (factureNet >= plafon) {
        PartAssu = plafon
      } else {
        PartAssu = Math.round((+factureNet - +PartClient))
      }
      return PartAssu
    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    /* conversion nombre en lettre */
    Unite (nombre) {
      let unite = ''
      switch (nombre) {
      case 0:
        unite = 'zéro'
        break
      case 1:
        unite = 'un'
        break
      case 2:
        unite = 'deux'
        break
      case 3:
        unite = 'trois'
        break
      case 4:
        unite = 'quatre'
        break
      case 5:
        unite = 'cinq'
        break
      case 6:
        unite = 'six'
        break
      case 7:
        unite = 'sept'
        break
      case 8:
        unite = 'huit'
        break
      case 9:
        unite = 'neuf'
        break
      }

      return unite
    },
    Dizaine (nombre) {
      let dizaine = ''
      switch (nombre) {
      case 10:
        dizaine = 'dix'
        break
      case 11:
        dizaine = 'onze'
        break
      case 12:
        dizaine = 'douze'
        break
      case 13:
        dizaine = 'treize'
        break
      case 14:
        dizaine = 'quatorze'
        break
      case 15:
        dizaine = 'quinze'
        break
      case 16:
        dizaine = 'seize'
        break
      case 17:
        dizaine = 'dix-sept'
        break
      case 18:
        dizaine = 'dix-huit'
        break
      case 19:
        dizaine = 'dix-neuf'
        break
      case 20:
        dizaine = 'vingt'
        break
      case 30:
        dizaine = 'trente'
        break
      case 40:
        dizaine = 'quarante'
        break
      case 50:
        dizaine = 'cinquante'
        break
      case 60:
        dizaine = 'soixante'
        break
      case 70:
        dizaine = 'soixante-dix'
        break
      case 80:
        dizaine = 'quatre-vingt'
        break
      case 90:
        dizaine = 'quatre-vingt-dix'
        break
      }
      return dizaine
    },
    NumberToLetter (nombre) {
      let quotient = 0, reste = 0
      let numberToLetter = ''
      //__________________________________

      if (nombre.toString().replace(/ /gi, '').length > 15) {
        return 'dépassement de capacité'
      }
      if (isNaN(nombre.toString().replace(/ /gi, ''))) {
        return 'Nombre non valide'
      }

      const nb = parseFloat(nombre.toString().replace(/ /gi, ''))
      if (Math.ceil(nb) !== nb) {
        return  'Nombre avec virgule non géré.'
      }

      const n = nb.toString().length
      switch (n) {
      case 1:
        numberToLetter = this.Unite(nb)
        break
      case 2:
        if (nb > 19) {
          quotient = Math.floor(nb / 10)
          reste = nb % 10
          if (nb < 71 || (nb > 79 && nb < 91)) {
            if (reste === 0) numberToLetter = this.Dizaine(quotient * 10)
            if (reste === 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-et-${  this.Unite(reste)}`
            if (reste > 1) numberToLetter = `${this.Dizaine(quotient * 10)  }-${  this.Unite(reste)}`
          } else numberToLetter = `${this.Dizaine((quotient - 1) * 10)  }-${  this.Dizaine(10 + reste)}`
        } else numberToLetter = this.Dizaine(nb)
        break
      case 3:
        quotient = Math.floor(nb / 100)
        reste = nb % 100
        if (quotient === 1 && reste === 0) numberToLetter = 'cent'
        if (quotient === 1 && reste !== 0) numberToLetter = `cent ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.Unite(quotient)  } cents`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.Unite(quotient)  } cent ${  this.NumberToLetter(reste)}`
        break
      case 4 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 5 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 6 :
        quotient = Math.floor(nb / 1000)
        reste = nb - (quotient * 1000)
        if (quotient === 1 && reste === 0) numberToLetter = 'mille'
        if (quotient === 1 && reste !== 0) numberToLetter = `mille ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } mille ${  this.NumberToLetter(reste)}`
        break
      case 7:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 8:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 9:
        quotient = Math.floor(nb / 1000000)
        reste = nb % 1000000
        if (quotient === 1 && reste === 0) numberToLetter = 'un million'
        if (quotient === 1 && reste !== 0) numberToLetter = `un million ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } millions ${  this.NumberToLetter(reste)}`
        break
      case 10:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 11:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 12:
        quotient = Math.floor(nb / 1000000000)
        reste = nb - (quotient * 1000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un milliard'
        if (quotient === 1 && reste !== 0) numberToLetter = `un milliard ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } milliards ${  this.NumberToLetter(reste)}`
        break
      case 13:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 14:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      case 15:
        quotient = Math.floor(nb / 1000000000000)
        reste = nb - (quotient * 1000000000000)
        if (quotient === 1 && reste === 0) numberToLetter = 'un billion'
        if (quotient === 1 && reste !== 0) numberToLetter = `un billion ${  this.NumberToLetter(reste)}`
        if (quotient > 1 && reste === 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions`
        if (quotient > 1 && reste !== 0) numberToLetter = `${this.NumberToLetter(quotient)  } billions ${  this.NumberToLetter(reste)}`
        break
      }//fin switch
      /*respect de l'accord de quatre-vingt*/
      if (numberToLetter.substr(numberToLetter.length - 'quatre-vingt'.length, 'quatre-vingt'.length) === 'quatre-vingt') numberToLetter = `${numberToLetter  }s`

      return numberToLetter.toUpperCase()
    },
    showReglementFactureAssurance (data) {
      this.allFactureClient = data.facturesClient
      this.donnees = data
      this.idFacture = data._id
      this.popupReglementFactureAssurance = true
    },
    showReglementInfo (data) {
      this.idFactureClient = data._id
      this.montanRegleAssu = this.calculParAssurance((data.PrixMont ? data.PrixMont : 0), (data.PrixVerG ? data.PrixVerG : 0), (data.PrixVerD ? data.PrixVerD : 0), (data.RemiseCom ? data.RemiseCom : 0), (data.PlafForf ? data.PlafForf : 0), this.calculParClient((data.PrixMont ? data.PrixMont : 0), (data.PrixVerG ? data.PrixVerG : 0), (data.PrixVerD ? data.PrixVerD : 0), (data.RemiseCom ? data.RemiseCom : 0), (data.PlafForf ? data.PlafForf : 0), (data.TickForf ? data.TickForf : 0), data.prixPhotogray, data.prixAntiReflet, data.prixAntiRayure, data.prixAutreTraitement), data.prixPhotogray, data.prixAntiReflet, data.prixAntiRayure, data.prixAutreTraitement)
      this.popupReglementFactureAssurance = false
      this.popupReglementInfo = true
    },
    showReglementInfoAll () {
      //this.idFactureClient = data._id
      //this.montanRegleAssu = this.calculParAssurance((data.PrixMont ? data.PrixMont : 0), (data.PrixVerG ? data.PrixVerG : 0), (data.PrixVerD ? data.PrixVerD : 0), (data.RemiseCom ? data.RemiseCom : 0), (data.PlafForf ? data.PlafForf : 0), this.calculParClient((data.PrixMont ? data.PrixMont : 0), (data.PrixVerG ? data.PrixVerG : 0), (data.PrixVerD ? data.PrixVerD : 0), (data.RemiseCom ? data.RemiseCom : 0), (data.PlafForf ? data.PlafForf : 0), (data.TickForf ? data.TickForf : 0), data.prixPhotogray, data.prixAntiReflet, data.prixAntiRayure, data.prixAutreTraitement), data.prixPhotogray, data.prixAntiReflet, data.prixAntiRayure, data.prixAutreTraitement)
      this.popupReglementFactureAssurance = false
      this.popupReglementInfoAll = true
    },
    factureAssurancePdfGenerator (facture) {
      console.log('data:', facture)
      const payload = {
        data: facture
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/factureAssurancePdfGenerator', payload)
        .then((response) => {
          this.$vs.loading.close()
          window.open(response.data.pdf)
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    factureAssuranceClientDetailPdfGenerator (id) {
      const payload = {
        idFactureGroupe: this.idFacture,
        idFactureClient: id
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/factureAssuranceClientDetailPdfGenerator', payload)
        .then((response) => {
          this.$vs.loading.close()
          window.open(response.data.url)
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    archiveFactureAssurance (idFacture) {
      const payload = {
        id: idFacture
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/archiveFactureAssurance', payload)
        .then((response) => {
          this.$vs.loading.close()

          if (response.data.message === 'archive impossible') {
            this.$vs.notify({
              title: 'Error',
              text: 'Impossible d\'archiver la facture',
              iconPack: 'feather',
              icon: 'icon-alert-triangle',
              color: 'danger'
            })
          }

          this.idFacture = null
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    soldeIndividualFactureAssurance () {
      const payload = {
        IdUser: this.IdUser,
        dateRegl: this.dateRegl,
        remarqueReglement: this.remarqueReglement,
        modePayement: this.modePayement,
        numeroCheq: this.numeroCheq,
        banque: this.banque,
        numeroCompteBq: this.numeroCompteBq,
        preuveReglement: this.preuveReglement,
        idFactureClient: this.idFactureClient,
        montanRegleAssu: this.montanRegleAssu,
        idFacture: this.idFacture
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/soldeIndividualFactureAssurance', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.popupReglementInfo = false
          this.popupReglementFactureAssurance = true
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.popupReglementInfo = false
          this.popupReglementFactureAssurance = true
          this.$vs.loading.close()
        })
    },
    soldeAllFactureAssurance () {
      const payload = {
        IdUser: this.IdUser,
        dateRegl: this.dateRegl,
        remarqueReglement: this.remarqueReglement,
        modePayement: this.modePayement,
        numeroCheq: this.numeroCheq,
        banque: this.banque,
        numeroCompteBq: this.numeroCompteBq,
        preuveReglement: this.preuveReglement,
        idFacture: this.idFacture,
        allFactureClient: this.allFactureClient
      }
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/soldeAllFactureAssurance', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.getFactureAssuranceDepose()

          this.popupReglementInfoAll = false
          this.popupReglementFactureAssurance = true
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.popupReglementInfoAll = false
          this.popupReglementFactureAssurance = true
          this.$vs.loading.close()
        })
    },
    getFactureAssuranceDepose () {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('facturation/getFactureAssuranceDepose')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getBanqueData () {
      if (this.$store.state.banque.banques.length === 0) {
        this.$store.dispatch('banque/fetchBanques')
          .then(() => {

          })
          .catch(err => {
            console.error(err)
            this.$vs.notify({
              title: 'error',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      }
    }
  },
  created () {
    this.getFactureAssuranceDepose()
    this.getBanqueData()
  }
}
</script>

<style scoped>
.customtable {
  border-collapse: collapse;
  width: 100%;
}
.customth, .customtd {
  border: 0.5px solid black;
  padding: 3px;
}

.customtd {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}
</style>

